import styled from "styled-components"

import { HREF_MINUT_HELP_SENSORS_OFFLINE } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { colorsV2 } from "src/ui/colors"
import { ExpandableAttentionCard } from "src/ui/ExpandableSection/ExpandableAttentionCard"
import AlertThreeBuildings from "src/ui/icons/illustrations/three-buildings-color-error.svg"
import NoiseMonitoringDisabledIcon from "src/ui/icons/noise-monitoring-disabled.svg"
import OccupancyDisabledIcon from "src/ui/icons/occupancy-detection-disabled.svg"
import SecurityAlarmDisabledIcon from "src/ui/icons/security-alarm-disabled.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { BodyMixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const wideWidth = "500px"

export function NonLiveBanner() {
  const { t, langKeys } = useTranslate()

  return (
    <ExpandableAttentionCard
      type="emergency"
      initialOpen={true}
      title={
        <MText variant="subtitle" color="contrast">
          {t(langKeys.home_unmonitored)}
        </MText>
      }
      summaryProps={{
        bgColor: colorsV2.systemWarning,
        fgColor: colorsV2.textContrast,
      }}
    >
      <ContainerBox>
        <DescriptionBox>
          <AlertDescription>
            <StyledBuildings />
            <div>
              {t(langKeys.home_unmonitored_all_sensors_offline_description)}{" "}
              <ExternalLink href={HREF_MINUT_HELP_SENSORS_OFFLINE}>
                {t(langKeys.read_more)}
              </ExternalLink>
            </div>
          </AlertDescription>

          <NoAlertBox>
            <div>{t(langKeys.home_unmonitored_no_alerts_title)}</div>
            <IconTextBox>
              <NoiseMonitoringDisabledIcon width={24} />
              <div>{t(langKeys.sound_noise_monitoring)}</div>
            </IconTextBox>
            <IconTextBox>
              <SecurityAlarmDisabledIcon width={24} />
              <div>{t(langKeys.home_security_alarm_title)}</div>
            </IconTextBox>
            <IconTextBox>
              <OccupancyDisabledIcon width={24} />
              <div>{t(langKeys.crowd_detect_title)}</div>
            </IconTextBox>
          </NoAlertBox>
        </DescriptionBox>
      </ContainerBox>
    </ExpandableAttentionCard>
  )
}

const DescriptionBox = styled.div`
  ${BodyMixin};
  display: grid;
  grid-template-columns: 1fr;
  width: auto;
  column-gap: ${spacing.XL3};
  row-gap: ${spacing.L};
  align-items: center;

  @container (width > ${wideWidth}) {
    grid-template-columns: 1fr 1fr;
  }
`

const AlertDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.L};
  border-bottom: 1px solid ${colorsV2.divider};
  align-items: center;
  justify-content: center;

  @container (width > ${wideWidth}) {
    border-right: 1px solid ${colorsV2.divider};
    padding-right: ${spacing.L};
    border-bottom: unset;
  }

  & > * {
    flex: 1 0 150px;
  }
`

const StyledBuildings = styled(AlertThreeBuildings)`
  width: 100%;
  max-width: 130px;
`

const IconTextBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`

const NoAlertBox = styled.div`
  display: grid;
  gap: ${spacing.S};
  align-content: start;
`

const ContainerBox = styled.div`
  container-type: inline-size;
`
